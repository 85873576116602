window.onload = () => {
  let currentScrollY = 0;
  let idOfHeader = 'header';
  let eleHeader = null;
  let hamburger = null;
  const classes = {
    pinned: 'static',
    unpinned: 'static-pinned',
    opened: 'opened',
  };

  let mobileSwiper = null;

  const swiperConfig = {
    loop: true,
    speed: 1000,
    autoplay: {
      delay: 3500,
      disableOnInteraction: false,
    },
    fadeEffect: {
      crossFade: true,
    },
    effect: 'fade',
  };

  const initSlider = (name, opt) => {
    const obj = Object.assign(swiperConfig, opt);
    const swiper = new Swiper('#' + name, obj);
    return swiper;
  };

  let laptopSwiper = initSlider('laptop');
  laptopSwiper.on('slideChange', (e) => slideChange(e));

  const stageSwiper = new Swiper('#stages', {
    effect: 'slide',
    loop: true,
    slidesPerView: 3,
    autoplay: false,
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 3,
      },
    },
  });

  const prevStage = document.querySelector('.stages-prev');
  const nextStage = document.querySelector('.stages-next');
  prevStage.onclick = () => stageSwiper.slidePrev();
  nextStage.onclick = () => stageSwiper.slideNext();

  let sliderTypes = document.querySelectorAll('.adaptive-type li');
  let SwiperPagination = document.querySelectorAll('.adaptive-list li');
  const SwiperMax = SwiperPagination.length;
  const slideChange = (e) => {
    SwiperPagination = document.querySelectorAll('.adaptive-list li');
    setActiveIndex(e);
  };

  const setActiveIndex = (e, _index) => {
    let index = _index !== undefined ? _index : e.activeIndex;
    index > SwiperMax ? (index = 1) : null;
    index < 1 ? (index = SwiperMax) : null;
    SwiperPagination.forEach((el) => el.classList.remove('active'));
    SwiperPagination[index - 1].classList.add('active');
  };

  const addSwiperPagination = () => {
    SwiperPagination.forEach((li, index) => {
      li.onclick = () => {
        if (laptopSwiper) {
          laptopSwiper.slideTo(index + 1);
        }

        if (mobileSwiper) {
          mobileSwiper.slideTo(index + 1);
        }
      };
    });
  };

  const initLaptop = () => {
    laptopSwiper = initSlider('laptop');
    laptopSwiper.slideTo(1);
    setActiveIndex(null, 1);
    laptopSwiper.on('slideChange', (e) => slideChange(e));
    mobileSwiper.destroy();
    mobileSwiper = undefined;
    document.querySelector('#mobile .swiper-wrapper').removeAttribute('style');
    document
      .querySelectorAll('#mobile .swiper-wrapper .swiper-slide')
      .forEach((el) => {
        el.removeAttribute('style');
      });
  };

  const initMobile = () => {
    mobileSwiper = initSlider('mobile');
    mobileSwiper.slideTo(1);
    setActiveIndex(null, 1);
    mobileSwiper.on('slideChange', (e) => slideChange(e));
    laptopSwiper.destroy();
    laptopSwiper = undefined;
    document.querySelector('#laptop .swiper-wrapper').removeAttribute('style');
    document
      .querySelectorAll('#laptop .swiper-wrapper .swiper-slide')
      .forEach((el) => {
        el.removeAttribute('style');
      });
  };

  const addSlidertEvents = () => {
    sliderTypes.forEach((li, index) => {
      li.onclick = () => {
        const type = li.getAttribute('data-type');
        const slider = document.querySelector('#' + type);

        sliderTypes.forEach((el) => el.classList.remove('active'));
        li.classList.add('active');

        document
          .querySelectorAll('.adaptive-slider')
          .forEach((el) => el.classList.remove('active'));
        slider.classList.add('active');

        switch (type) {
          case 'laptop':
            initLaptop();
            break;
          case 'mobile':
            initMobile();
            break;
          default:
            break;
        }
      };
    });
  };

  function onScroll() {
    currentScrollY = window.pageYOffset;
    if (currentScrollY > 10) {
      unpin();
    } else {
      pin();
    }
  }

  function pin() {
    if (eleHeader.classList.contains(classes.unpinned)) {
      eleHeader.classList.remove(classes.unpinned);
      eleHeader.classList.add(classes.pinned);
    }
  }

  function unpin() {
    if (
      eleHeader.classList.contains(classes.pinned) ||
      !eleHeader.classList.contains(classes.unpinned)
    ) {
      eleHeader.classList.remove(classes.pinned);
      eleHeader.classList.add(classes.unpinned);
    }
  }

  const scrollEvent = () => {
    eleHeader = document.querySelector(idOfHeader);
    onScroll();
    document.addEventListener('scroll', onScroll, false);
  };

  const humburgerEvent = () => {
    hamburger = eleHeader.querySelector('.hamburger');
    hamburger.onclick = () => {
      if (eleHeader.classList.contains(classes.opened)) {
        eleHeader.classList.remove(classes.opened);
      } else {
        eleHeader.classList.add(classes.opened);
      }
    };
  };

  const initHeaderLinks = () => {
    const links = document.querySelectorAll('[data-scroll-target]');
    links.forEach((el) => {
      el.onclick = (e) => {
        hamburger.click();
        e.preventDefault();
        const target = el.getAttribute('data-scroll-target');
        const element = document.querySelector(
          '[data-scroll="' + target + '"]'
        );
        const headerOffset = document.querySelector('header').clientHeight + 25;
        const elementPosition = element.offsetTop;
        const offsetPosition = elementPosition - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
      };
    });
  };

  scrollEvent();
  humburgerEvent();
  addSwiperPagination();
  addSlidertEvents();
  initHeaderLinks();
};
